<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    hide-footer
    title="Информация о полисах"
    header-class="monitoring-create-call-header"
    @hidden="onClose"
    @close="onClose"
  >
    <template>
      <div class="mb-[24px]">
        <div class="active-policies">
          <PatientDetailPolicy
            v-for="(policy, index) in newPolicies"
            :key="policy.id + index"
            :policy="policy"
            :person-id="patientId"
          />

          <PatientDetailPolicy
            v-for="(policy, index) in activePolicies"
            :key="policy.id + index"
            :policy="policy"
            :person-id="patientId"
          />

          <PatientDetailPolicy
            v-for="(policy, index) in pendingPolicies"
            :key="policy.id + index"
            :policy="policy"
            :person-id="patientId"
          />
          <div
            v-if="activePolicies.length === 0 && pendingPolicies.length === 0 && newPolicies.length === 0"
            class="not-found mt-3"
          >
            Нет полисов
          </div>
        </div>
        <div
          v-if="inactivePolicies.length"
          class="inactive-policies"
        >
          <div class="redline" />
          <div class="redline-text">
            Неактивные полисы
          </div>
          <PatientDetailPolicy
            v-for="(policy, index) in inactivePolicies"
            :key="policy.id + index"
            :policy="policy"
            :person-id="patientId"
          />
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { FEATURES_FOR_CHAT, FEATURES_FOR_PATIENTS } from '@/helpers/consts';
import PatientDetailPolicy from '@/components/Patients/PatientDetailPolicy';

export default {
  name: 'MonitoringPoliciesModal',
  components: { PatientDetailPolicy },
  props: {
    patientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpen: true,
      policyStatuses: {
        inactive: [0, 1, 4, 5],
        pending: [2],
        active: [3],
      },
      newPolicies: [],
      FEATURES_FOR_CHAT,
      FEATURES_FOR_PATIENTS,
      policies: [],
    };
  },
  computed: {
    activePolicies() {
      let array = [];
      if (this.policies.length > 0) array = this.policies?.filter((policy) => this.policyStatuses.active.includes(policy.policyStatus));
      return array;
    },
    pendingPolicies() {
      let array = [];
      if (this.policies.length > 0) array = this.policies?.filter((policy) => this.policyStatuses.pending.includes(policy.policyStatus));
      return array;
    },
    inactivePolicies() {
      let array = [];
      if (this.policies.length > 0) array = this.policies?.filter((policy) => this.policyStatuses.inactive.includes(policy.policyStatus));
      return array;
    },
  },
  async created() {
    const patient = await this.$store.dispatch(this.$types.PATIENT_FETCH, this.patientId);
    this.policies = patient.policies;
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
  },
};
</script>

<style scoped>

</style>
